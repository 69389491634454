const defaultStyles: any = {
	border: 'none',
	'z-index': 2147483647,
	height: '100vh',
	width: '100vw',
	display: 'block !important',
	visibility: 'visible',
	background: 'none transparent',
	opacity: 1,
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	'pointer-events': 'auto',
	'touch-action': 'auto',
	position: 'fixed',
};

interface IConfig {
	buttonStyle: {
		backgroundColor: string;
	};
	tags: string[];
	source: string;
	readonly order: {
		name: string;
		description: string;
		price: number;
		quantity: number;
	};
}

interface IWidget {
	window?: any;
	config: IConfig | null;
	iframe: HTMLIFrameElement | null;
	loadingSpinner: HTMLDivElement | null;
	showLoadingSpinner: () => void; // New function to show the loading spinner
	hideLoadingSpinner: () => void;
	createLoadingDiv: () => void;
	exposeCloseWidget: () => void;
	styleElement: null | HTMLStyleElement;
	init: (config: IConfig) => void;
	setupListeners: () => void;
	createIframe: () => void;
	handleMessage: (event: MessageEvent) => void;
	closeWidget: () => void;
}

const CobuyrWidget: IWidget = {
	iframe: null,
	config: null,
	styleElement: null,
	loadingSpinner: null,
	init: function (config: IConfig) {
		this.config = config;
		this.createIframe();
	},
	closeWidget: function () {
		window.parent.postMessage(JSON.stringify({ action: 'close' }), '*');
	},
	createLoadingDiv: function () {
		if (this.loadingSpinner) {
			// Check if the iframe has a content document
			const contentDocument = this.loadingSpinner.ownerDocument;
			if (contentDocument) {
				// Create a div with an id for styling
				const loadingDiv = contentDocument.createElement('div');
				loadingDiv.id = 'loadingDiv';
				loadingDiv.style.position = 'absolute';
				loadingDiv.style.top = '50%';
				loadingDiv.style.left = '50%';
				loadingDiv.style.transform = 'translate(-50%, -50%)';

				// Add the spinner HTML and style directly within the iframe content
				loadingDiv.innerHTML = `<div class="spinner"></div>`;

				// Append the loading div to the body of the iframe
				contentDocument.body?.appendChild(loadingDiv);

				// Include the spinner CSS within the iframe content
				const styleElement = contentDocument.createElement('style');
				styleElement.innerHTML = `
          		@keyframes spin {
           		 0% { transform: rotate(0deg); }
           		 100% { transform: rotate(360deg); }
          		}

          .spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-left: 4px solid #3498db;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
          }
        `;

				// Append the style element to the head of the iframe document
				contentDocument.head?.appendChild(styleElement);
				this.styleElement = styleElement;
			}
		}
	},
	createIframe: function () {
		this.loadingSpinner = document.createElement('div');

		var container: HTMLDivElement = document.createElement('div');
		container.setAttribute('id', 'connect-widget-div');

		document.body.appendChild(this.loadingSpinner);
		this.showLoadingSpinner();
		this.createLoadingDiv();

		this.iframe = document.createElement('iframe');
		this.iframe.id = 'cobuyriFrame';

		let styles = '';

		for (let key in defaultStyles) {
			styles += key + ': ' + defaultStyles[key] + ';';
		}
		this.iframe.setAttribute('style', styles);

		this.iframe.src = `${process.env.REACT_APP_COBUYR_WIDGET}`;

		this.iframe.allow = 'clipboard-write *';

		this.iframe.referrerPolicy = 'no-referrer';

		document.body.appendChild(this.iframe);

		this.setupListeners();
	},
	showLoadingSpinner: function () {
		if (this.loadingSpinner) {
			this.loadingSpinner.style.display = 'block';
		}
	},
	hideLoadingSpinner: function () {
		if (this.styleElement && this.styleElement.parentNode) {
			this.styleElement.parentNode.removeChild(this.styleElement);
		}
		if (this.loadingSpinner) {
			this.loadingSpinner = null;
		}
	},
	setupListeners: function () {
		window.addEventListener('message', this.handleMessage.bind(this));
	},
	handleMessage: function (e) {
		e.preventDefault();

		if (!e.data || typeof e.data !== 'string') return;

		let data = JSON.parse(e.data);
		switch (data.action) {
			case 'init': {
				if (this.iframe) {
					this.iframe.contentWindow?.postMessage(JSON.stringify(this.config), '*');
					this.hideLoadingSpinner();
				}
				break;
			}
			case 'close': {
				if (this.iframe) {
					document.body.removeChild(this.iframe);

					window.removeEventListener('message', this.handleMessage.bind(this), false);

					this.loadingSpinner = null;
					this.config = null;
					this.iframe = null;
				}
				break;
			}
			default:
				break;
		}
	},
	exposeCloseWidget: function () {
		// Expose a function that allows calling closeWidget externally
		return this.closeWidget.bind(this);
	},
};

export default CobuyrWidget;
